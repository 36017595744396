<template >
  <div v-loading="loading">
    <img :src="getAuthorizedSignatureById.auth_sign_url" height="50" />
    <p>{{getAuthorizedSignatureById.name}}</p>
    <p>{{getAuthorizedSignatureById.designation}}</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name:"templates-formComponentsExecute-AuthorizedSignatureExecute",
  components: {},
  props: ["data", "value","fromEntityViews"],
  computed: {
    ...mapGetters("company", ["getAuthorizedSignatureById"]),
    getStyle() {
      return `height:${this.data.height - 30}px`;
    }
  },
  data() {
    return {
      validations: [],
      options: [],
      loading: false
    };
  },
  mounted() {
    this.fetchAuthorizedSignature();
  },
  watch: {
    "data.authorized_signature"() {
      this.fetchAuthorizedSignature();
    }
  },
  methods: {
    async fetchAuthorizedSignature() {
      this.loading = true;
      if (this.data.authorized_signature) {
        await this.$store.dispatch(
          "company/getCompanyAuthorizationSignatureById",
          {
            id: this.data.authorized_signature
          }
        );
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100% !important;
  .el-input,
  .el-input__inner {
    height: inherit !important;
  }
}
</style>